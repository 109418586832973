import { app } from "@/firebase";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAuth } from "firebase/auth";
import { PropsWithChildren } from "react";

const authLink = setContext(async (_, { headers }) => {
  const auth = getAuth(app);
  const token = await auth.currentUser?.getIdToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
});

const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV === "development",
  cache: new InMemoryCache({
    typePolicies: {
      Profile: {
        keyFields: [],
      },
    },
  }),
  link: from([authLink, httpLink]),
});

export function ApolloWrapper({ children }: PropsWithChildren) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
