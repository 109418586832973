import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthTracker } from "./auth-tracker";
import { PageViewTracker } from "./page-view-tracker";

type Stage = "page" | "identify" | "track" | "track:user" | "track:anonymous";
type Identity = "anonymous" | "user";
type EventFn = () => void;

const orderedStages: Stage[] = ["page", "identify", "track"];

export type AnalyticsContextValue = {
  register: (stage: Stage, fn: EventFn) => void;
  stage: Stage;
  setStage: (stage: Stage) => void;
  identity?: Identity;
  setIdentity: (identity: Identity) => void;
};

const AnalyticsContext = createContext<AnalyticsContextValue>({
  register: () => {},
  stage: "page",
  setStage: () => {},
  identity: undefined,
  setIdentity: () => {},
});

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const [stage, setStage] = useState<Stage>("page");
  const [identity, setIdentity] = useState<Identity>();
  const [events, setEvents] = useState({} as Record<Stage, EventFn[]>);

  const register: AnalyticsContextValue["register"] = useCallback(
    (stage: Stage, fn: EventFn) => {
      setEvents((events) => ({
        ...events,
        [stage]: [...(events?.[stage] || []), fn],
      }));
    },
    []
  );
  const unregister = useCallback((fn: EventFn) => {
    setEvents(
      (events) =>
        Object.fromEntries(
          Object.entries(events).map(([key, fns]) => [
            key,
            fns.filter((f) => f !== fn),
          ])
        ) as Record<Stage, EventFn[]>
    );
  }, []);

  useEffect(() => {
    const step = orderedStages.indexOf(stage);
    const next = orderedStages.slice(0, step + 1).reduce((next, stage) => {
      if (next) {
        return next;
      }

      if (!events[stage]?.length) {
        if (
          stage === "track" &&
          identity &&
          events[`track:${identity}`]?.length
        ) {
          return events[`track:${identity}`][0] ?? null;
        }
        return null;
      }

      return events[stage][0] ?? null;
    }, null as EventFn | null);

    if (next) {
      next();
      unregister(next);
    }
  }, [stage, events, unregister, identity]);

  return (
    <AnalyticsContext.Provider
      value={{ register, stage, setStage, identity, setIdentity }}
    >
      <AuthTracker />
      <PageViewTracker />
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  return useContext(AnalyticsContext);
}
