import { useCallback } from "react";
import { useAnalytics } from "./analytics-context";
import { useSegmentAnalytics } from "./segment-context";

export function usePage() {
  const { analytics } = useSegmentAnalytics();
  const { register, setStage } = useAnalytics();

  const page = useCallback(
    (category?: string, name?: string, properties?: any) => {
      if (!analytics) return;
      register("page", () => {
        analytics.page(category, name, properties);
        setStage("identify");
      });
    },
    [analytics, register, setStage]
  );

  if (analytics === undefined) {
    throw new Error("usePageView must be used within a SegmentProvider");
  }

  return page;
}
