import { useApolloClient } from "@apollo/client";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { app } from "./firebase";

export function ApolloAuthSync() {
  const client = useApolloClient();
  const auth = getAuth(app);

  useEffect(() => {
    onAuthStateChanged(auth, () => {
      client.resetStore();
    });
  }, [client]);

  return null;
}
