import { useRouter } from "next/router";
import { useEffect } from "react";
import { usePage } from "./use-page";

export function PageViewTracker() {
  const { events } = useRouter();
  const pageView = usePage();

  useEffect(() => {
    pageView();
  }, [pageView]);

  useEffect(() => {
    const handleRouteChange = () => {
      pageView();
    };

    events.on("routeChangeComplete", handleRouteChange);

    return () => {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [events, pageView]);

  return null;
}
