"use client";

import { useFirebaseApp } from "@vetsie/ai-firebase";
import { FirebaseError } from "firebase/app";
import {
  EmailAuthProvider,
  browserLocalPersistence,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { useCallback, useMemo } from "react";
import { useAuthContext } from "./auth-provider";
import { AuthError } from "./error";

export const useAuth = () => {
  const { token } = useAuthContext();

  const app = useFirebaseApp();
  const auth = useMemo(() => getAuth(app), [app]);

  const login = useCallback(
    async (email: string, password: string) => {
      if (!email || !password) {
        throw new AuthError(
          "Email and password are required",
          "auth/missing-credentials"
        );
      }

      try {
        await setPersistence(auth, browserLocalPersistence);
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error: any) {
        if (
          error.code === "auth/user-not-found" ||
          error.code === "auth/wrong-password"
        ) {
          throw new AuthError(
            "Invalid email or password",
            "auth/invalid-credentials"
          );
        }

        if (error.code === "auth/too-many-requests") {
          throw new AuthError(
            "Too many failed login attempts.",
            "auth/too-many-requests"
          );
        }

        throw error;
      }
    },
    [auth]
  );

  const logout = useCallback(async () => signOut(auth), [auth]);

  const forgotPassword = useCallback(
    (email: string) => {
      return sendPasswordResetEmail(auth, email);
    },
    [auth]
  );

  const changeEmail = useCallback(
    async (email: string) => {
      try {
        return await updateEmail(auth.currentUser!, email);
      } catch (error: any) {
        if (error instanceof FirebaseError) {
          // TODO: Handle reauth required
          if (error.code === "auth/email-already-in-use") {
            throw new AuthError(
              "Email already in use",
              "auth/email-already-in-use"
            );
          }
          if (error.code === "auth/requires-recent-login") {
            throw new AuthError(
              "Please reauthenticate to change your email",
              "auth/requires-recent-login"
            );
          }
        }

        throw error;
      }
    },
    [auth]
  );

  const changePassword = useCallback(
    async (password: string) => {
      try {
        return await updatePassword(auth.currentUser!, password);
      } catch (error: any) {
        if (error instanceof FirebaseError) {
          if (error.code === "auth/requires-recent-login") {
            throw new AuthError(
              "Please reauthenticate to change your password",
              "auth/requires-recent-login"
            );
          }
        }

        throw error;
      }
    },
    [auth]
  );

  const reauthenticate = useCallback(
    async (password: string) => {
      if (!password) {
        throw new AuthError("Password is required", "auth/missing-credentials");
      }

      try {
        await reauthenticateWithCredential(
          auth.currentUser!,
          EmailAuthProvider.credential(auth.currentUser!.email!, password)
        );
      } catch (error: any) {
        if (error.code === "auth/wrong-password") {
          throw new AuthError("Invalid password", "auth/invalid-credentials");
        }

        if (error.code === "auth/too-many-requests") {
          throw new AuthError(
            "Too many failed login attempts.",
            "auth/too-many-requests"
          );
        }

        throw error;
      }
    },
    [auth]
  );

  return {
    token,
    changeEmail,
    changePassword,
    forgotPassword,
    login,
    logout,
    reauthenticate,
  };
};
