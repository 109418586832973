import { AppProvider } from "@/components/app-provider";
import "@/styles/globals.css";
import { openSans } from "@vetsie/ai-ui/src/fonts";
import clsx from "clsx";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode } from "react";

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <AppProvider segmentWriteKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <div
        className={clsx(
          "text-sm leading-[1.125rem] tracking-[0.16px] text-black/60 dark:text-white/70",
          "bg-white dark:bg-gray-800",
          "transition-colors duration-500",
          "h-screen w-screen",
          `${openSans.variable} font-sans`
        )}
      >
        {getLayout(<Component {...pageProps} />)}
      </div>
    </AppProvider>
  );
}
