import { useCallback, useEffect } from "react";
import { useAnalytics } from "./analytics-context";
import { useSegmentAnalytics } from "./segment-context";

export function useIdentify() {
  const { analytics } = useSegmentAnalytics();
  const { register, stage, setStage, identity, setIdentity } = useAnalytics();

  const anonymous = useCallback(
    (traits: any) => {
      if (!analytics) return;
      register("identify", () => {
        analytics.identify(traits);
        setIdentity("anonymous");
        setStage("track");
      });
    },
    [analytics, register, setIdentity, setStage]
  );
  const user = useCallback(
    (userId: string, traits?: any) => {
      if (!analytics) return;
      register("identify", () => {
        analytics.identify(userId, traits);
        setIdentity("user");
        setStage("track");
      });
    },
    [analytics, register, setIdentity, setStage]
  );

  useEffect(() => {
    if (stage === "identify" && identity) {
      setStage("track");
    }
  }, [identity, setStage, stage]);

  if (analytics === undefined) {
    throw new Error("useIdentify must be used within a SegmentProvider");
  }

  return {
    anonymous,
    user,
  };
}
