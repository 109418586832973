import { useCallback } from "react";
import { useAnalytics } from "./analytics-context";
import { useSegmentAnalytics } from "./segment-context";

export function useTrack() {
  const { analytics } = useSegmentAnalytics();
  const { register } = useAnalytics();

  const track = useCallback(
    (event: string, properties?: any) => {
      if (!analytics) return;
      register("track", () => {
        analytics.track(event, properties);
      });
    },
    [analytics, register]
  );

  const trackUser = useCallback(
    (event: string, properties?: any) => {
      if (!analytics) return;
      register("track:user", () => {
        analytics.track(event, properties);
      });
    },
    [analytics, register]
  );

  const trackAnonymous = useCallback(
    (event: string, properties?: any) => {
      if (!analytics) return;
      register("track:anonymous", () => {
        analytics.track(event, properties);
      });
    },
    [analytics, register]
  );

  return {
    track,
    trackUser,
    trackAnonymous,
  };
}
