import { ApolloAuthSync } from "@/apollo-auth-sync";
import { ApolloWrapper } from "@/apollo-wrapper";
import { app } from "@/firebase";
import { AnalyticsProvider, SegmentProvider } from "@vetsie/ai-analytics";
import { AuthProvider } from "@vetsie/ai-auth";
import { FirebaseProvider } from "@vetsie/ai-firebase";
import { PropsWithChildren } from "react";

export function AppProvider({
  segmentWriteKey,
  children,
}: PropsWithChildren<{
  segmentWriteKey?: string;
}>) {
  return (
    <SegmentProvider writeKey={segmentWriteKey}>
      <FirebaseProvider app={app}>
        <AuthProvider>
          <ApolloWrapper>
            <ApolloAuthSync />
            <AnalyticsProvider>{children}</AnalyticsProvider>
          </ApolloWrapper>
        </AuthProvider>
      </FirebaseProvider>
    </SegmentProvider>
  );
}
