import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext, useContext, useMemo } from "react";

type SegmentContextValue = {
  analytics: AnalyticsBrowser | null;
};
const SegmentContext = createContext<SegmentContextValue>({ analytics: null });

export function SegmentProvider({
  children,
  writeKey,
}: {
  children: React.ReactNode;
  writeKey?: string;
}) {
  const analytics = useMemo(() => {
    if (!writeKey) return null;

    const analytics = new AnalyticsBrowser();
    return analytics.load({
      writeKey,
    });
  }, [writeKey]);

  return (
    <SegmentContext.Provider
      value={{
        analytics,
      }}
    >
      {children}
    </SegmentContext.Provider>
  );
}

export function useSegmentAnalytics() {
  return useContext(SegmentContext);
}
