export class AuthError extends Error {
  constructor(
    message: string,
    public code:
      | "auth/missing-credentials"
      | "auth/invalid-credentials"
      | "auth/too-many-requests"
      | "auth/email-already-in-use"
      | "auth/requires-recent-login"
  ) {
    super(message);
    this.name = "AuthError";
  }
}
