import { useCallback } from "react";
import { useAnalytics } from "./analytics-context";
import { useSegmentAnalytics } from "./segment-context";

export function useReset() {
  const { analytics } = useSegmentAnalytics();
  const { setIdentity } = useAnalytics();

  const reset = useCallback(() => {
    if (!analytics) return;
    analytics.reset();
    setIdentity("anonymous");
  }, [analytics, setIdentity]);

  if (analytics === undefined) {
    throw new Error("useReset must be used within a SegmentProvider");
  }

  return reset;
}
