import { useQuery } from "@apollo/client";
import { useAuth } from "@vetsie/ai-auth";
import { useEffect, useState } from "react";
import { graphql } from "./__generated__";
import { useIdentify } from "./use-identify";
import { useReset } from "./use-reset";

const GET_USER = graphql(/* GraphQL */ `
  query AuthTrackerUser {
    me {
      id
      email
      fullName
      displayName
      email
      clinicName
      createdAt
    }
  }
`);

export function AuthTracker() {
  const { data } = useQuery(GET_USER, {
    errorPolicy: "ignore",
  });
  const { user } = useIdentify();
  const reset = useReset();
  const { token } = useAuth();
  const [lastToken, setLastToken] = useState(token);

  useEffect(() => {
    setLastToken(token);

    if (token === null && lastToken !== null) {
      reset();
    }
  }, [lastToken, reset, token]);

  useEffect(() => {
    if (!token) return;
    if (!data?.me) return;

    user(data.me.id, {
      id: data.me.id,
      email: data.me.email,
      name: data.me.fullName,
      displayName: data.me.displayName,
      company: data.me.clinicName && {
        name: data.me.clinicName,
      },
      createdAt: data.me.createdAt,
    });
  }, [data, token, user]);

  return null;
}
