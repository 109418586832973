/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  ElapsedTime: { input: any; output: any };
};

export type AdditionalEntityFields = {
  path?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssistantParticipant = Participant & {
  __typename?: "AssistantParticipant";
  avatar?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use message.chat */
  chat: Chat;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type AssistantParticipantNameArgs = {
  format?: InputMaybe<UserNameFormat>;
};

export type ChallengeInput = {
  recaptcha_token: Scalars["String"]["input"];
};

export type Chat = {
  __typename?: "Chat";
  createdAt: Scalars["String"]["output"];
  currentVersion: ChatVersion;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  participants: Array<User>;
  preview?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
  versions: Array<ChatVersion>;
};

export type ChatCreatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChatUpdatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChatConnection = Connection & {
  __typename?: "ChatConnection";
  edges: Array<ChatEdge>;
  id: Scalars["ID"]["output"];
  pageInfo: PageInfo;
};

export type ChatCreatePayload = PayloadV2 & {
  __typename?: "ChatCreatePayload";
  chat?: Maybe<Chat>;
  userErrors: Array<UserError>;
};

export type ChatCreatePayloadV3 = PayloadV2 & {
  __typename?: "ChatCreatePayloadV3";
  chat?: Maybe<Chat>;
  userErrors: Array<UserError>;
};

export type ChatDeletePayload = Payload & {
  __typename?: "ChatDeletePayload";
  errors: Array<UserError>;
};

export type ChatDeleteV2Payload = PayloadV2 & {
  __typename?: "ChatDeleteV2Payload";
  userErrors: Array<UserError>;
};

export type ChatEdge = ConnectionEdge & {
  __typename?: "ChatEdge";
  cursor: Scalars["String"]["output"];
  node: Chat;
};

export type ChatInput = {
  name: Scalars["String"]["input"];
};

export enum ChatSortField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  UpdatedAt = "UPDATED_AT",
}

export type ChatSortInput = {
  direction: SortDirection;
  field: ChatSortField;
};

export type ChatUpdatePayload = Payload & {
  __typename?: "ChatUpdatePayload";
  chat?: Maybe<Chat>;
  errors: Array<UserError>;
};

export type ChatUpdateV2Payload = PayloadV2 & {
  __typename?: "ChatUpdateV2Payload";
  chat?: Maybe<Chat>;
  userErrors: Array<UserError>;
};

export type ChatVersion = {
  __typename?: "ChatVersion";
  chat: Chat;
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastMessage?: Maybe<Message>;
  messages: Array<Message>;
  updatedAt: Scalars["String"]["output"];
  version: Scalars["Int"]["output"];
};

export type ChatVersionCreatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChatVersionUpdatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type Citation = {
  __typename?: "Citation";
  text?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

export type Connection = {
  id: Scalars["ID"]["output"];
  pageInfo: PageInfo;
};

export type ConnectionEdge = {
  cursor: Scalars["String"]["output"];
};

export type CredentialsInput = {
  email: Scalars["String"]["input"];
  password?: InputMaybe<Scalars["String"]["input"]>;
};

export enum DateFormat {
  Elapsed = "ELAPSED",
  Global = "GLOBAL",
  Local = "LOCAL",
}

export enum EngineActor {
  Ai = "AI",
  Human = "HUMAN",
  System = "SYSTEM",
}

export type EngineHistory = {
  __typename?: "EngineHistory";
  createdAt: Scalars["String"]["output"];
  engineMessages: Array<EngineMessage>;
  id: Scalars["ID"]["output"];
  messages: Array<Message>;
  usage?: Maybe<EngineUsage>;
};

export type EngineHistoryCreatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type EngineMessage = {
  __typename?: "EngineMessage";
  actor: EngineActor;
  text: Scalars["String"]["output"];
};

export type EngineUsage = {
  __typename?: "EngineUsage";
  openAi: OpenAiUsage;
};

export enum Locale {
  EnCa = "EN_CA",
}

export type Message = {
  __typename?: "Message";
  chat: Chat;
  /** @deprecated Use sources */
  citations?: Maybe<Array<Citation>>;
  createdAt: Scalars["String"]["output"];
  engineHistory?: Maybe<Array<EngineMessage>>;
  id: Scalars["ID"]["output"];
  participant: Participant;
  sources?: Maybe<Array<Source>>;
  text: Scalars["String"]["output"];
  updatedAt: Scalars["String"]["output"];
};

export type MessageCreatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type MessageUpdatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type MessageInput = {
  text: Scalars["String"]["input"];
};

export type MessageSendPayload = PayloadV2 & {
  __typename?: "MessageSendPayload";
  chat?: Maybe<Chat>;
  userErrors: Array<UserError>;
};

export type MessageSendPayloadV3 = PayloadV2 & {
  __typename?: "MessageSendPayloadV3";
  chat?: Maybe<Chat>;
  userErrors: Array<UserError>;
};

export enum MessageSourceType {
  Article = "ARTICLE",
  Generated = "GENERATED",
}

export type Mutation = {
  __typename?: "Mutation";
  chatCreate: ChatCreatePayload;
  /** @deprecated Use chatCreate */
  chatCreateV3: ChatCreatePayloadV3;
  /** @deprecated Use chatDeleteV2 */
  chatDelete: ChatDeletePayload;
  chatDeleteV2: ChatDeleteV2Payload;
  /** @deprecated Use chatUpdateV2 */
  chatUpdate: ChatUpdatePayload;
  chatUpdateV2: ChatUpdateV2Payload;
  messageSend: MessageSendPayload;
  messageSendV3: MessageSendPayloadV3;
  /** @deprecated Use profileUpdateV2 */
  profileUpdate: ProfileUpdatePayload;
  profileUpdateV2: ProfileUpdateV2Payload;
  /** @deprecated Use registerV2 */
  register: RegisterPayload;
  registerV2: RegisterV2Payload;
};

export type MutationChatCreateArgs = {
  chat: ChatInput;
  message?: InputMaybe<MessageInput>;
};

export type MutationChatCreateV3Args = {
  chat: ChatInput;
  message?: InputMaybe<MessageInput>;
};

export type MutationChatDeleteArgs = {
  chatId: Scalars["ID"]["input"];
};

export type MutationChatDeleteV2Args = {
  chatId: Scalars["ID"]["input"];
};

export type MutationChatUpdateArgs = {
  chat: ChatInput;
  chatId: Scalars["ID"]["input"];
};

export type MutationChatUpdateV2Args = {
  chat: ChatInput;
  chatId: Scalars["ID"]["input"];
};

export type MutationMessageSendArgs = {
  message: MessageInput;
  replyToMessageId: Scalars["ID"]["input"];
};

export type MutationMessageSendV3Args = {
  message: MessageInput;
  replyToMessageId: Scalars["ID"]["input"];
};

export type MutationProfileUpdateArgs = {
  profile: ProfileInput;
};

export type MutationProfileUpdateV2Args = {
  profile: ProfileInput;
};

export type MutationRegisterArgs = {
  profile: ProfileInput;
};

export type MutationRegisterV2Args = {
  profile: ProfileInput;
};

export type OpenAiChat = {
  __typename?: "OpenAiChat";
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  messages: Array<OpenAiMessage>;
  sourceMessage: Message;
  updatedAt: Scalars["String"]["output"];
};

export type OpenAiChatCreatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type OpenAiChatUpdatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type OpenAiFunctionCall = {
  __typename?: "OpenAiFunctionCall";
  arguments: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type OpenAiMessage = {
  __typename?: "OpenAiMessage";
  chat: OpenAiChat;
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["String"]["output"];
  functionCall?: Maybe<OpenAiFunctionCall>;
  name?: Maybe<Scalars["String"]["output"]>;
  role: OpenAiMessageRole;
  updatedAt: Scalars["String"]["output"];
  usage?: Maybe<OpenAiUsage>;
};

export type OpenAiMessageCreatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type OpenAiMessageUpdatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export enum OpenAiMessageRole {
  Assistant = "ASSISTANT",
  Function = "FUNCTION",
  System = "SYSTEM",
  User = "USER",
}

export type OpenAiUsage = {
  __typename?: "OpenAiUsage";
  completionTokens: Scalars["Int"]["output"];
  promptTokens: Scalars["Int"]["output"];
  totalTokens?: Maybe<Scalars["Int"]["output"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type Participant = {
  avatar?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use message.chat */
  chat: Chat;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ParticipantNameArgs = {
  format?: InputMaybe<UserNameFormat>;
};

export enum ParticipantType {
  Assistant = "ASSISTANT",
  User = "USER",
}

export type Payload = {
  errors: Array<UserError>;
};

export type PayloadV2 = {
  userErrors: Array<UserError>;
};

export type ProfileInput = {
  challenge?: InputMaybe<ChallengeInput>;
  credentials: CredentialsInput;
  profile: UserInput;
};

export type ProfileUpdatePayload = Payload & {
  __typename?: "ProfileUpdatePayload";
  errors: Array<UserError>;
  profile?: Maybe<User>;
};

export type ProfileUpdateV2Payload = PayloadV2 & {
  __typename?: "ProfileUpdateV2Payload";
  profile?: Maybe<User>;
  userErrors: Array<UserError>;
};

export type Query = {
  __typename?: "Query";
  chat?: Maybe<Chat>;
  /** @deprecated Use chatsV2 */
  chats: Array<Chat>;
  chatsV2: ChatConnection;
  me: User;
  user?: Maybe<User>;
  users: UserConnection;
};

export type QueryChatArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryChatsV2Args = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ChatSortInput>>;
  participantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserSortInput>>;
};

export type RegisterPayload = Payload & {
  __typename?: "RegisterPayload";
  errors: Array<UserError>;
  profile?: Maybe<User>;
};

export type RegisterV2Payload = PayloadV2 & {
  __typename?: "RegisterV2Payload";
  profile?: Maybe<User>;
  userErrors: Array<UserError>;
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type Source = {
  __typename?: "Source";
  citation?: Maybe<Citation>;
  type: MessageSourceType;
};

export type User = {
  __typename?: "User";
  chats: ChatConnection;
  clinicName?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["String"]["output"];
  /** @deprecated Use name */
  displayName?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  /** @deprecated Use name */
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isAdmin: Scalars["Boolean"]["output"];
  isMe: Scalars["Boolean"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["String"]["output"];
};

export type UserChatsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ChatSortInput>>;
};

export type UserCreatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserNameArgs = {
  format?: InputMaybe<UserNameFormat>;
};

export type UserUpdatedAtArgs = {
  format?: InputMaybe<DateFormat>;
  locale?: InputMaybe<Locale>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserConnection = Connection & {
  __typename?: "UserConnection";
  edges: Array<UserEdge>;
  id: Scalars["ID"]["output"];
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: "UserEdge";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type UserError = {
  __typename?: "UserError";
  field?: Maybe<Array<Scalars["String"]["output"]>>;
  message: Scalars["String"]["output"];
};

export type UserInput = {
  clinicName?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  fullName: Scalars["String"]["input"];
};

export enum UserNameFormat {
  Default = "DEFAULT",
  Display = "DISPLAY",
  Full = "FULL",
  Initials = "INITIALS",
}

export type UserParticipant = Participant & {
  __typename?: "UserParticipant";
  avatar?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use message.chat */
  chat: Chat;
  name?: Maybe<Scalars["String"]["output"]>;
  user: User;
};

export type UserParticipantNameArgs = {
  format?: InputMaybe<UserNameFormat>;
};

export enum UserSortField {
  ClinicName = "CLINIC_NAME",
  CreatedAt = "CREATED_AT",
  DisplayName = "DISPLAY_NAME",
  Email = "EMAIL",
  FullName = "FULL_NAME",
  UpdatedAt = "UPDATED_AT",
}

export type UserSortInput = {
  direction: SortDirection;
  field: UserSortField;
};

export type AuthTrackerUserQueryVariables = Exact<{ [key: string]: never }>;

export type AuthTrackerUserQuery = {
  __typename?: "Query";
  me: {
    __typename?: "User";
    id: string;
    email: string;
    fullName: string;
    displayName?: string | null;
    clinicName?: string | null;
    createdAt: string;
  };
};

export const AuthTrackerUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthTrackerUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "fullName" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "clinicName" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthTrackerUserQuery,
  AuthTrackerUserQueryVariables
>;
