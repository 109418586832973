"use client";

import { FirebaseApp } from "firebase/app";
import React, { createContext, useContext } from "react";

interface FirebaseContextProps {
  app: FirebaseApp;
}

const FirebaseContext = createContext<FirebaseContextProps>(
  {} as FirebaseContextProps
);

export function FirebaseProvider({
  app,
  children,
}: React.PropsWithChildren<FirebaseContextProps>) {
  return (
    <FirebaseContext.Provider value={{ app }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export function useFirebaseApp() {
  return useContext(FirebaseContext).app;
}
