import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";
import { useRouter } from "next/router";
import { useEffect } from "react";

interface UserQuery {
  me?: {
    id: string;
  };
}

export type UserOptions = {
  redirectTo?: string;
  redirectIfFound?: boolean;
};

export function useUser<
  TData extends UserQuery = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { redirectTo, redirectIfFound }: UserOptions = {}
) {
  const { data, loading } = useQuery(query, {
    errorPolicy: "all",
  });
  const { push } = useRouter();

  useEffect(() => {
    if (!redirectTo) return;
    if (loading) return;

    if (
      (redirectIfFound && data?.me?.id) ||
      (!redirectIfFound && !data?.me?.id)
    ) {
      push(redirectTo, undefined, { unstable_skipClientCache: true });
    }
  }, [data, loading, push, redirectIfFound, redirectTo]);

  return {
    data,
    loading,
  };
}
