import { useFirebaseApp } from "@vetsie/ai-firebase";
import addYears from "date-fns/addYears";
import { User, getAuth, onIdTokenChanged } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";

interface AuthContextProps {
  token?: string | null | undefined;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

interface AuthProviderProps {
  children?: React.ReactNode;
}

function setCookie(value: string, expires: Date): void {
  const cookieName: string = "token";
  const domain: string = window.location.hostname;
  const path: string = "/";

  document.cookie = `${cookieName}=${value};expires=${expires.toUTCString()};domain=${domain};path=${path};`;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null | undefined>();
  const [token, setToken] = useState<string | null | undefined>();

  const app = useFirebaseApp();

  useEffect(() => {
    if (!app) return;

    return onIdTokenChanged(getAuth(app), (user) => {
      setUser(user);
    });
  }, [app]);

  useEffect(() => {
    async function run() {
      if (user === undefined) return;

      if (!user) {
        setToken(null);
        return;
      }

      const token = await user.getIdToken();
      setToken(token);
    }

    run();
  }, [user]);

  useEffect(() => {
    if (token === undefined) return;

    if (!token) {
      setCookie("", new Date(0));
      return;
    }

    setCookie(token, addYears(new Date(), 1));
  }, [token]);

  return (
    <AuthContext.Provider value={{ token }}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
